import SubscriptionPolicy from '../../../policies/subscription.policy';
import { SUBSCRIPTION_PLAN } from '../../../modules/sprints/module.init';

export default class SprintEditForm {
    static showFunctionality(fields) {
        if (SubscriptionPolicy.viewTab(SUBSCRIPTION_PLAN)) return fields;

        return fields.filter(item => !['estimated_points', 'sprint_id', 'actual_points'].includes(item.key));
    }
}
