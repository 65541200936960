<template>
    <div class="container crud">
        <div class="at-container crud__content crud__edit-view">
            <div class="page-controls">
                <h1 class="control-item title">
                    {{ $route.params.id ? `${$t(pageData.title)}` : `${$t(pageData.title)}` }}
                </h1>
                <div class="control-items">
                    <template v-if="pageData.pageControls && pageData.pageControls.length > 0">
                        <template v-for="(button, key) of pageData.pageControls">
                            <at-button
                                v-if="checkRenderCondition(button)"
                                :key="key"
                                class="control-item"
                                size="large"
                                :type="button.renderType || ''"
                                :icon="button.icon || ''"
                                @click="handleClick(button)"
                            >
                                {{ $t(button.label) }}
                            </at-button>
                        </template>
                    </template>
                    <at-button size="large" class="control-item at-btn--primary" @click="$router.go(-1)"
                        >{{ $t('control.back') }}
                    </at-button>
                </div>
            </div>

            <component :is="component" v-for="(component, index) of pageData.topComponents" :key="index" :parent="this">
            </component>
            <validation-observer ref="form" v-slot="{ invalid }">
                <div class="data-entries">
                    <template v-for="(field, key) of fields">
                        <template v-if="isDisplayable(field)">
                            <div :key="key" class="data-entry">
                                <div class="row">
                                    <div class="col-6">
                                        <at-tooltip
                                            v-if="field.tooltipValue"
                                            :content="$t(field.tooltipValue)"
                                            placement="top-left"
                                        >
                                            <u class="label label-tooltip">
                                                {{ $t(field.label) }}
                                                <span v-if="field.required">*</span>
                                            </u>
                                        </at-tooltip>
                                        <p v-else class="label">
                                            {{ $t(field.label) }}
                                            <span v-if="field.required">*</span>
                                        </p>
                                    </div>
                                    <at-input
                                        v-if="isDataLoading && pageData.type === 'edit'"
                                        class="col-18"
                                        disabled
                                    />
                                    <div v-else class="col-18">
                                        <validation-provider
                                            v-if="typeof field.render !== 'undefined'"
                                            v-slot="{ errors }"
                                            :rules="
                                                typeof field.rules === 'string'
                                                    ? field.rules
                                                    : field.required
                                                    ? 'required'
                                                    : ''
                                            "
                                            :name="$t(field.label)"
                                            :vid="field.key"
                                        >
                                            <renderable-field
                                                v-model="values[field.key]"
                                                :render="field.render"
                                                :field="field"
                                                :values="values"
                                                :setValue="setValue"
                                                :class="{
                                                    'at-select--error at-input--error has-error': errors.length > 0,
                                                }"
                                            />
                                            <small>{{ errors[0] }}</small>
                                        </validation-provider>

                                        <validation-provider
                                            v-else-if="field.key === 'email'"
                                            v-slot="{ errors }"
                                            :rules="field.required ? 'required|email' : ''"
                                            :name="field.key"
                                            :vid="field.key"
                                        >
                                            <at-input
                                                v-model="values[field.key]"
                                                :placeholder="$t(field.placeholder) || ''"
                                                :type="field.frontendType || ''"
                                                :status="errors.length > 0 ? 'error' : ''"
                                            ></at-input>
                                            <small>{{ errors[0] }}</small>
                                        </validation-provider>

                                        <validation-provider
                                            v-else-if="field.type === 'input' || field.type === 'text'"
                                            v-slot="{ errors }"
                                            :rules="field.required ? 'required' : ''"
                                            :name="$t(field.label)"
                                            :vid="field.key"
                                        >
                                            <at-input
                                                v-model="values[field.key]"
                                                :placeholder="$t(field.placeholder) || ''"
                                                :type="field.frontendType || ''"
                                                :status="errors.length > 0 ? 'error' : ''"
                                                @input="debouncedCompanyRequest(field.key, $event)"
                                            >
                                            </at-input>
                                            <small>{{ errors[0] }}</small>
                                        </validation-provider>

                                        <validation-provider
                                            v-else-if="field.type === 'number'"
                                            v-slot="{ errors }"
                                            :rules="field.required ? 'required' : ''"
                                            :name="$t(field.label)"
                                            :vid="field.key"
                                        >
                                            <at-input-number
                                                v-model="values[field.key]"
                                                :min="field.minValue"
                                                :max="field.maxValue"
                                            ></at-input-number>
                                            <small>{{ errors[0] }}</small>
                                        </validation-provider>

                                        <validation-provider
                                            v-else-if="field.type === 'select'"
                                            v-slot="{ errors }"
                                            :rules="field.required ? 'required' : ''"
                                            :name="$t(field.label)"
                                            :vid="field.key"
                                        >
                                            <at-select
                                                v-model="values[field.key]"
                                                :class="{
                                                    'at-select--error': errors.length > 0,
                                                }"
                                                :placeholder="$t('control.select')"
                                            >
                                                <at-option
                                                    v-for="(option, optionKey) of field.options"
                                                    :key="optionKey"
                                                    :value="option.value"
                                                    >{{ ucfirst($t(option.label)) }}
                                                </at-option>
                                            </at-select>
                                            <small>{{ errors[0] }}</small>
                                        </validation-provider>

                                        <validation-provider
                                            v-else-if="field.type === 'checkbox'"
                                            v-slot="{ errors }"
                                            :rules="field.required ? 'required' : ''"
                                            :name="$t(field.label)"
                                            :vid="field.key"
                                        >
                                            <at-checkbox v-model="values[field.key]" label="" />
                                            <small>{{ errors[0] }}</small>
                                        </validation-provider>

                                        <validation-provider
                                            v-else-if="field.type === 'resource-select'"
                                            v-slot="{ errors }"
                                            :rules="field.required ? 'required' : ''"
                                            :name="$t(field.label)"
                                            :vid="field.key"
                                        >
                                            <resource-select
                                                v-model="values[field.key]"
                                                :service="field.service"
                                                :class="{
                                                    'at-select--error': errors.length > 0,
                                                }"
                                            />
                                            <small>{{ errors[0] }}</small>
                                        </validation-provider>

                                        <validation-provider
                                            v-else-if="field.type === 'textarea'"
                                            v-slot="{ errors }"
                                            :rules="field.required ? 'required' : ''"
                                            :name="$t(field.label)"
                                            :vid="field.key"
                                        >
                                            <at-textarea
                                                v-model="values[field.key]"
                                                autosize
                                                min-rows="2"
                                                :class="{
                                                    'at-textarea--error': errors.length > 0,
                                                }"
                                                :placeholder="$t(field.placeholder) || ''"
                                            />
                                            <small>{{ errors[0] }}</small>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
                <component
                    :is="component"
                    v-for="(component, index) of pageData.bottomComponents"
                    :key="index"
                    :parent="this"
                />
                <at-button type="primary" :disabled="invalid || isLoading" :loading="isLoading" @click="submit">{{
                    $t('control.save')
                }}</at-button>
            </validation-observer>
        </div>
    </div>
</template>

<script>
    import RenderableField from '@/components/RenderableField';
    import ResourceSelect from '@/components/ResourceSelect';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import { ucfirst } from '@/utils/string';
    import { debounce } from 'lodash';
    import SprintEditForm from './fixes/sprint-edit-form';

    export default {
        name: 'EditView',
        components: {
            RenderableField,
            ResourceSelect,
            ValidationProvider,
            ValidationObserver,
        },

        data() {
            const meta = this.$route.meta;
            const pageData = meta.pageData || {};

            return {
                service: meta.service,
                fields: meta.fields || [],
                values: {},
                filters: this.$route.meta.filters,

                pageData: {
                    title: pageData.title,
                    topComponents: pageData.topComponents || [],
                    bottomComponents: pageData.bottomComponents || [],
                    type: pageData.type || 'new',
                    routeNamedSection: pageData.editRouteName || '',
                    pageControls: this.$route.meta.pageData.pageControls || [],
                    editRouteName: pageData.editRouteName || '',
                },

                redirectPath: meta.redirectPath,

                isLoading: false,
                isDataLoading: false,
                afterSubmitCallback: meta.afterSubmitCallback,
            };
        },

        async mounted() {
            if (!Object.values(this.values).length) {
                await this.fetchData();
                if (this.pageData.type === 'edit' && this.pageData.title.includes('statuses')) {
                    if (this.values.system) {
                        this.fields = this.fields.filter(item => item.key === 'visible' || item.key === 'color');
                    }
                }
            }
            if (this.pageData.title.includes('tasks')) {
                this.fields = SprintEditForm.showFunctionality(this.fields);
            }
        },

        async beforeRouteEnter(to, from, next) {
            next(async vm => {
                await vm.fetchData();
                next();
            });
        },

        async beforeRouteUpdate(to, from, next) {
            await this.fetchData();
            next();
        },

        methods: {
            ucfirst,
            debouncedCompanyRequest: debounce(function () {
                if (this.pageData.type === 'new') {
                    if (this.values.company_number && this.values.country_id) {
                        this.updateFild();
                    }
                }
            }, 1000),

            async updateFild() {
                const params = { company_number: this.values.company_number, country_id: this.values.country_id };
                const res = await this.service.autoComplete(params);
                if (res.data.length == 0 || res.data.data.length >= 2) {
                    this.fields = this.$route.meta.fields;
                    return;
                }
                const { ['id']: id, ...data } = res.data.data[0].attributes;
                Object.entries(data).forEach(([key, value]) => {
                    this.values[key] = value;
                });
                this.fields = [
                    {
                        label: 'field.company_name',
                        key: 'name',
                        type: 'text',
                        placeholder: 'field.name',
                        required: true,
                    },
                    {
                        label: 'field.description',
                        key: 'description',
                        type: 'text',
                        placeholder: 'field.description',
                    },
                    {
                        label: 'field.company_number',
                        key: 'company_number',
                        type: 'text',
                        placeholder: 'field.company_number',
                        required: true,
                    },
                    {
                        label: 'field.street_address',
                        key: 'street_address',
                        type: 'text',
                        placeholder: 'field.street_address',
                        required: true,
                    },
                    {
                        label: 'field.zip',
                        key: 'zip',
                        type: 'text',
                        placeholder: 'field.zip',
                        required: true,
                    },
                    {
                        label: 'field.city',
                        key: 'city',
                        type: 'text',
                        placeholder: 'field.city',
                        required: true,
                    },
                    {
                        label: 'field.country',
                        key: 'country',
                        type: 'text',
                        placeholder: 'field.country',
                        required: true,
                    },
                    {
                        label: 'field.url',
                        key: 'url',
                        type: 'text',
                        placeholder: 'field.url',
                    },
                ];
            },
            async fetchData() {
                this.isDataLoading = true;
                if (this.pageData.type === 'edit') {
                    try {
                        const { data } = await this.service.getItem(this.$route.params.id);
                        this.values = { ...this.values, ...data.data.attributes };
                    } catch ({ response }) {
                        if (
                            response &&
                            Object.prototype.hasOwnProperty.call(response, 'data') &&
                            response.data.error_type === 'query.item_not_found'
                        ) {
                            this.$router.replace({ name: 'forbidden' });
                        }
                    }
                } else if (this.pageData.type === 'new') {
                    this.fields.forEach(field => {
                        if (field.default !== undefined) {
                            this.values[field.key] =
                                typeof field.default === 'function' ? field.default(this.$store) : field.default;
                        }
                    });
                }

                this.isDataLoading = false;
            },

            async submit() {
                const valid = await this.$refs.form.validate();
                if (!valid) {
                    return;
                }

                this.isLoading = true;
                try {
                    const data =
                        this.pageData.type === 'new'
                            ? await this.service.save(this.values, this.$route.params.id)
                            : await this.service.update(this.values, this.$route.params.id);

                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.record.save.success.title'),
                        message: this.$t('notification.record.save.success.message'),
                    });
                    this.isLoading = false;

                    let redirectRoute = this.redirectPath.withID
                        ? { path: this.redirectPath.path + data.data.data.id }
                        : { path: this.redirectPath.path };

                    this.$router.push(redirectRoute);
                } catch {
                    this.isLoading = false;
                }
            },

            handleClick(button) {
                button.onClick(this, this.values[this.service.getIdParam()]);
            },

            checkRenderCondition(button) {
                return typeof button.renderCondition !== 'undefined' ? button.renderCondition(this) : true;
            },

            setValue(key, value) {
                this.$set(this.values, key, value);
            },

            isDisplayable(field) {
                if (typeof field.displayable === 'function') {
                    return field.displayable(this);
                }

                if (typeof field.displayable !== 'undefined') {
                    return !!field.displayable;
                }

                return true;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .crud__edit-view {
        & ::v-deep .quill-editor {
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 10px;
        }

        & ::v-deep .ql-toolbar {
            .theme-dark & {
                border: 1px solid #555;
                border-radius: 5px 5px 0 0;
            }
        }

        & ::v-deep .ql-container {
            .theme-dark {
                border: 1px solid #555;
                border-radius: 0 0 5px 5px;
                min-height: 200px;
            }
        }

        & ::v-deep .ql-editor {
            .theme-dark & {
                p {
                    color: #ffa500;
                }
            }
        }

        /* Dark theme icon color */
        .theme-dark & ::v-deep .ql-toolbar button.ql-bold .ql-stroke,
        .theme-dark & ::v-deep .ql-toolbar button.ql-italic .ql-stroke,
        .theme-dark & ::v-deep .ql-toolbar button.ql-underline .ql-stroke,
        .theme-dark & ::v-deep .ql-toolbar button.ql-strike .ql-stroke,
        .theme-dark & ::v-deep .ql-toolbar button.ql-link .ql-stroke,
        .theme-dark & ::v-deep .ql-toolbar button .ql-fill,
        .theme-dark & ::v-deep .ql-toolbar span.ql-picker-label,
        .theme-dark & ::v-deep .ql-toolbar button .ql-stroke {
            stroke: #ffa500 !important;
        }

        .theme-dark & ::v-deep .ql-toolbar .ql-formats span.ql-picker-options {
            background-color: #555;
            color: #ffa500;
        }

        .theme-dark & ::v-deep .ql-toolbar span .ql-stroke {
            stroke: #ffa500;
        }

        /* Active/hover/focus state icon color */
        & ::v-deep .ql-toolbar button:hover .ql-stroke,
        & ::v-deep .ql-toolbar button:focus .ql-stroke,
        & ::v-deep .ql-toolbar button.ql-active .ql-stroke,
        & ::v-deep .ql-toolbar button:hover .ql-fill,
        & ::v-deep .ql-toolbar button:focus .ql-fill,
        & ::v-deep .ql-toolbar button.ql-active .ql-fill {
            color: #c4c4cf !important;
            stroke: #c4c4cf !important;
        }
    }

    .page-controls {
        margin-bottom: 1.5em;
        display: flex;
        justify-content: space-between;

        .control-item {
            margin-right: 0.5em;

            &:last-child {
                margin-right: 0;
            }
        }

        .title {
            margin-right: 1.5em;
            font-size: 1.6rem;
            .theme-dark & {
                color: #ffa500;
            }
        }
    }

    .data-entries {
        .data-entry {
            margin-bottom: $layout-02;

            .label {
                font-weight: bold;
                .theme-dark & {
                    color: #ffa500;
                }
            }
        }
    }
</style>
